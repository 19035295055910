import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";

const initialState = {
  allAuthorisations: [],
  authorisation: [],
  myAuthorisation: [],
  status: "idle",
  error: null,
};

export const getAllAuthorisations = createAsyncThunk(
  "authorisations/getAllAuthorisations",
  async () => {
    let data;
    try {
      const response = await axios.get(`/authorisations`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const addAuthorisation = createAsyncThunk(
  "authorisations/addAuthorisation",
  async (body,thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/authorisations`, body);

      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getMyAuthorisations());
        return {
          data: data,
          message: "Your authorisation request is successfully submited ! ",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const approveAuthorisation = createAsyncThunk(
  "authorisations/approveAuthorisation",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/authorisations/approve/${id}`);

      data = await response.data;

      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllAuthorisations());
        return data.message;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);
export const rejectAuthorisationRequest = createAsyncThunk(
  "authorisations/rejectAuthorisationRequest",
  async (data, thunkAPI) => {
    const { id, descriptionReject } = data;
    try {
      const response = await axios.patch(
        `/authorisations/reject/${id}
    `,
        { descriptionReject: descriptionReject }
      );
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllAuthorisations());
        return data.message;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const deleteAuthorisation = createAsyncThunk(
  "authorisations/deleteAuthorisation",
  async (id, thunkAPI) => {
    let data;
    try {
      const response = await axios.patch(`/authorisations/delete/${id}`);
      data = await response.data;
      if ((response.status = 200)) {
        thunkAPI.dispatch(getAllAuthorisations());
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const getMyAuthorisations = createAsyncThunk(
  "authorisations/getMyLeaves",
  async () => {
    let data;
    try {
      const response = await axios.get(`/authorisations/my-authorisations`);
      data = await response.data;

      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

export const editAuthorisationCurrentUser = createAsyncThunk(
  "authorisations/editAuthorisationCurrentUser",
  async (body, thunkAPI) => {
    let data;
    const { description, startTime, endTime, date } = body.Data;
    try {
      const response = await axios.patch(`/authorisations/edit/${body.id}`, {
        description,
        date,
        startTime,
        endTime,
      });

      data = await response.data;

      if (response.status === 200) {
        thunkAPI.dispatch(getMyAuthorisations());
        return {
          data: data,
          message: "Your authorisation request has been updated successfully.",
        };
      }
      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(
        err.message
          ? err.message
          : err
          ? err
          : "Oups !Something went wrong! please try again"
      );
    }
  }
);

const slice = createSlice({
  name: "authorisation",
  initialState,
  reducers: {
    handleNewAuthorisation: (state, action) => {
      state.allAuthorisations.push(action.payload.newAuthorisation);
    },
  },
  extraReducers: {
    [getAllAuthorisations.pending]: (state) => {
      state.status = "loading";
    },
    [getAllAuthorisations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.allAuthorisations = action.payload;
    },
    [getAllAuthorisations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },

    [addAuthorisation.pending]: (state) => {
      state.status = "loading";
    },
    [addAuthorisation.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.authorisation = action.payload;
    },
    [addAuthorisation.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [getMyAuthorisations.pending]: (state) => {
      state.status = "loading";
    },
    [getMyAuthorisations.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.myAuthorisation = action.payload;
    },
    [getMyAuthorisations.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
  },
});
export const { handleNewAuthorisation } = slice.actions;

export const reducer = slice.reducer;

export default slice;
